import "./Product.css";

export default function Product({
  title,
  prevPrice = null,
  curntPrice,
  img,
  offPrice = null,
}) {
  const addOffPrice = () => {
    return <span className="off-price">%{offPrice}</span>;
  };
  const addPrevPrice = () => {
    return <span className="prev-price">{prevPrice} تومان</span>;
  };

  return (
    <>
      <div className="product-single">
        {offPrice !== null ? addOffPrice() : null}
        <div className="prd-img">
          <img src={img} alt="product" />
        </div>
        <h3 className="prd-title">{title}</h3>
        <div className="prd-items">
          <a href="#" className="bx bx-heart"></a>
          <a href="#" className="bx bx-cart"></a>
        </div>
        <div className="prd-prices">
          {prevPrice !== null ? addPrevPrice() : null}
          <span className="curnt-price">{curntPrice} تومان</span>
        </div>
      </div>
    </>
  );
}
