import './Category.css';

export default function Category({img="/assets/category-1.jpg", title="تیشرت"}) {
  return(
    <>
      <div className="category">
        <div className="cat-img">
          <img src={img} alt="img" />
        </div>
        <h4 className="title">{title}</h4>
      </div>
    </>
  )
}