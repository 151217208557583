// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./Slider.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import Product from "../Product/Product";

export default function Slider({ elementName, data, sldTitle, link, sldImg }) {
  return (
    <>
      <div className="slider">
        <Swiper
          dir="rtl"
          slidesPerView={3}
          spaceBetween={50}
          navigation={{
            prevEl: elementName + " .slider .prev",
            nextEl: elementName + " .slider .next",
            disabledClass: "disable",
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          width={700}
          breakpoints={{
            900: {
              slidesPerView: 2,
            },

            1200: {
              slidesPerView: 3,
            },
          }}
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Product
                  title={item.title}
                  img={item.img}
                  curntPrice={item.curntPrice}
                  prevPrice={item.prevPrice}
                  offPrice={item.offPrice}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="right-side">
          <div className="sld-btns">
            <span className="sld-btn next bx bx-chevron-left"></span>
            <span className="sld-btn prev bx bx-chevron-right"></span>
          </div>
          <div className="sld-extra-description">
            <div>
              <h2 className="sld-title">{sldTitle}</h2>
              <a href={link} className="sld-link">
                مشاهده همه
              </a>
            </div>
            <div className="sld-img">
              <img src={sldImg} alt="Slider" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
