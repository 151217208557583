import "./MainSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

const data = [
  {
    img: "/assets/slider-1.jpg",
  },
  {
    img: "/assets/slider-2.jpg",
  },
];

export default function MainSlider() {
  return (
    <>
      <div className="main-slider">
        <Swiper
          navigation={{
            nextEl: ".main-slider-next",
            prevEl: ".main-slider-prev",
            disabledClass: "disable",
          }}
          modules={[Navigation]}
          dir="rtl"
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={item.img} alt="img" />
              </SwiperSlide>
            );
          })}
          <div className="sld-btns">
            <span className="main-slider-prev prev bx bx-chevron-right"></span>
            <span className="main-slider-next next bx bx-chevron-left"></span>
          </div>
        </Swiper>
      </div>
    </>
  );
}
