import "./SpecialProduct.css";

export default function SpecialProduct() {
  return (
    <>
      <div className="spc-product">
        <div className="off-price">
          <p>50% تخفیف</p>
        </div>
        <div className="product">
          <div className="prd-img">
            <img src='/assets/product-1.jpg' alt="product" />
          </div>
          <br />
          <div className="prd-detail">
            <h3 className="prd-title">کلاه زمستانی رنگ زرد</h3>
            <br />
            <p className="prd-price">
              <span className="curent-price">125000 تومان</span>
              <span className="prev-price">250000 تومان</span>
            </p>
          </div>
        </div>
        <br />
        <div className="product-items">
          <a href="#" className="add-cart">
            افزودن به سبد خرید
          </a>
          <a href="#" className="bx bx-heart"></a>
        </div>
      </div>
    </>
  );
}
