export default function MainFooter() {
  return (
    <>
      <footer>
        <div className="container">
          <section className="row socials">
            <div className="contact-us">
              <h2>24 ساعته پاسخگوی شما هستیم</h2>
              <div className="contus-items">
                <span>
                  02100000
                  <i className="bx bx-phone"></i>
                </span>
                <span>
                  info@shikson.com
                  <i className="bx bx-envelope"></i>
                </span>
              </div>
            </div>
            <div className="logo">
              <img src="/assets/logo.png" alt="logo" />
            </div>
            <div className="social-medias">
              <h2>مارا در شبکه های اجتماعی دنبال کنید</h2>
              <div className="sc-items">
                <a href="#" className="social-media bx bxl-youtube"></a>
                <a href="#" className="social-media bx bxl-facebook"></a>
                <a href="#" className="social-media bx bxl-telegram"></a>
                <a href="#" className="social-media bx bxl-whatsapp"></a>
                <a href="#" className="social-media bx bxl-instagram"></a>
              </div>
            </div>
          </section>
          <section className="row services">
            <div className="email">
              <h2>دریافت تخفیفات ویژه در ایمیل شما</h2>
              <p>
                برای دریافت جدیدترین مقالات و محصولات و تخفیفات کافیست در
                خبرنامه ایمیلی ما عضو شده و هر هفته در ایمیل خود بخوانید ما هم
                همانند شما از هرزنامه بیزاریم
              </p>
            </div>
            <div className="lists">
              <ul className="list">
                <h2 className="ls-title">لباس های زمستانی</h2>
                <br />
                <li>
                  <a href="#">کافشن</a>
                </li>
                <li>
                  <a href="#">هودی و کلاه</a>
                </li>
                <li>
                  <a href="#">سوییشرت و بافت</a>
                </li>
              </ul>
              <ul className="list">
                <h2 className="ls-title">لباس های زمستانی</h2>
                <br />
                <li>
                  <a href="#">کافشن</a>
                </li>
                <li>
                  <a href="#">هودی و کلاه</a>
                </li>
                <li>
                  <a href="#">سوییشرت و بافت</a>
                </li>
              </ul>
              <ul className="list">
                <h2 className="ls-title">لباس های زمستانی</h2>
                <br />
                <li>
                  <a href="#">کافشن</a>
                </li>
                <li>
                  <a href="#">هودی و کلاه</a>
                </li>
                <li>
                  <a href="#">سوییشرت و بافت</a>
                </li>
              </ul>
            </div>
          </section>
          <section className="row about-site">
            <div className="ab-description">
              <h2 className="title">درباره شیکسون بیشتر بدانید</h2>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
                استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
                نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
                کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
                جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و
                زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات
                پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
              </p>
            </div>
            <div className="ab-symbols">
              <a href="#" className="item">
                <img src="/assets/enamad.png" alt="enamad" />
              </a>
              <a href="#" className="item">
                <img src="/assets/enamad.png" alt="enamad" />
              </a>
              <a href="#" className="item">
                <img src="/assets/enamad.png" alt="enamad" />
              </a>
            </div>
          </section>
        </div>
        <section className="copyright">
          <p>تمامی حقوق مادی و معنوی سایت محفوظ می باشد.</p>
        </section>
      </footer>
    </>
  );
}
