import ThirdSlider from "../../components/ThirdSlider/ThirdSlider";
import MainSlider from "../../components/MainSlider/MainSlider";
import SecondrySlider from "../../components/SecondrySlider/SecondrySlider";
import SpecialProduct from "../../components/SpecialProduct/SpecialProduct";
import MainHeader from "../../layouts/MainHeader/MainHeader";
import CategorySecond from "../../components/CategorySecond/CategorySecond";
import Slider from "../../components/Slider/Slider";
import MainFooter from "../../layouts/MainFooter/MainFooter";

const productList1 = [
  {
    title: "کلاه زمستانی زرد",
    img: "/assets/product-1.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "تیشرت مردانه مشکی",
    img: "/assets/product-2.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "عینک آفتابی مدل گرد ",
    img: "/assets/product-8.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "پالتو زنانه مدل تدی بلند رنگ سرمه ای ",
    img: "/assets/product-7.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "هودی مردانه طرح چنگ",
    img: "/assets/product-5.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "عینک آفتابی مدل دسته سه بعدی",
    img: "/assets/product-10.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "کلاه زمستانی زرد",
    img: "/assets/product-1.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "تیشرت مردانه مشکی",
    img: "/assets/product-2.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
];
const productList2 = [
  {
    title: "پالتو زنانه مدل تدی بلند رنگ سرمه ای ",
    img: "/assets/product-7.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "کلاه مردانه مدل 0011",
    img: "/assets/product-3.png",
    curntPrice: 250000,
  },
  {
    title: "تیشرت مردانه مشکی",
    img: "/assets/product-2.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "تیشرت آستین بلند مردانه",
    img: "/assets/product-4.png",
    curntPrice: 250000,
  },
  {
    title: "هودی مردانه طرح چنگ",
    img: "/assets/product-5.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "کلاه بافتنی زنانه ",
    img: "/assets/product-6.jpg",
    curntPrice: 250000,
  },
];
const productList3 = [
  {
    title: "عینک آفتابی مدل گرد ",
    img: "/assets/product-8.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "عینک آفتابی مدل 204",
    img: "/assets/product-9.jpg",
    curntPrice: 250000,
  },
  {
    title: "عینک آفتابی مدل دسته سه بعدی",
    img: "/assets/product-10.jpg",
    offPrice: 50,
    curntPrice: 125000,
    prevPrice: 250000,
  },
  {
    title: "عینک شب مدل Night View NV",
    img: "/assets/product-11.jpg",
    curntPrice: 250000,
  },
  {
    title: "عینک آفتابی لاگوست مدل L 815 6012138 POLARIZED N",
    img: "/assets/product-12.jpg",
    curntPrice: 250000,
  },
];

const categories = [
  {
    img: "/assets/category-1.jpg",
    title: "تیشرت",
  },
  {
    img: "/assets/category-2.jpg",
    title: "عینک",
  },
  {
    img: "/assets/category-3.jpg",
    title: "کفش",
  },
  {
    img: "/assets/category-4.jpg",
    title: "هودی",
  },
  {
    img: "/assets/category-5.jpg",
    title: "کیف",
  },
  {
    img: "/assets/category-1.jpg",
    title: "تیشرت",
  },
  {
    img: "/assets/category-2.jpg",
    title: "عینک",
  },
  {
    img: "/assets/category-3.jpg",
    title: "کفش",
  },
  {
    img: "/assets/category-4.jpg",
    title: "هودی",
  },
  {
    img: "/assets/category-5.jpg",
    title: "کیف",
  },
  {
    img: "/assets/category-1.jpg",
    title: "تیشرت",
  },
  {
    img: "/assets/category-2.jpg",
    title: "عینک",
  },
  {
    img: "/assets/category-3.jpg",
    title: "کفش",
  },
  {
    img: "/assets/category-4.jpg",
    title: "هودی",
  },
  {
    img: "/assets/category-5.jpg",
    title: "کیف",
  },
  {
    img: "/assets/category-1.jpg",
    title: "تیشرت",
  },
  {
    img: "/assets/category-2.jpg",
    title: "عینک",
  },
  {
    img: "/assets/category-3.jpg",
    title: "کفش",
  },
  {
    img: "/assets/category-4.jpg",
    title: "هودی",
  },
  {
    img: "/assets/category-5.jpg",
    title: "کیف",
  },
  {
    img: "/assets/category-1.jpg",
    title: "تیشرت",
  },
  {
    img: "/assets/category-2.jpg",
    title: "عینک",
  },
  {
    img: "/assets/category-3.jpg",
    title: "کفش",
  },
  {
    img: "/assets/category-4.jpg",
    title: "هودی",
  },
  {
    img: "/assets/category-5.jpg",
    title: "کیف",
  },
];

const categoriesSecond = [
  {
    img: "/assets/category-1.jpg",
    title: "برند های منتخب تیشرت",
    body: "بهترین برندها با مناسب ترین قیمت",
    link: "#",
    bgColor: "blue",
  },
  {
    img: "/assets/category-2.jpg",
    title: "برند های منتخب تیشرت",
    body: "بهترین برندها با مناسب ترین قیمت",
    link: "#",
    bgColor: "red",
  },
  {
    img: "/assets/category-4.jpg",
    title: "برند های منتخب تیشرت",
    body: "بهترین برندها با مناسب ترین قیمت",
    link: "#",
    bgColor: "green",
  },
  {
    img: "/assets/category-5.jpg",
    title: "برند های منتخب تیشرت",
    body: "بهترین برندها با مناسب ترین قیمت",
    link: "#",
    bgColor: "orange",
  },
];

const articles = [
  {
    img: "/assets/article-1.jpg",
    category: "دسته بندی",
    title: "عنوان بلاگ شماره یک",
    date: "21 دی ماه 1400",
  },
  {
    img: "/assets/article-2.jpg",
    category: "دسته بندی",
    title: "عنوان بلاگ شماره دو",
    date: "21 دی ماه 1400",
  },
  {
    img: "/assets/article-3.jpg",
    category: "دسته بندی",
    title: "عنوان بلاگ شماره سه",
    date: "21 دی ماه 1400",
  },
  {
    img: "/assets/article-4.jpg",
    category: "دسته بندی",
    title: "عنوان بلاگ شماره چهار",
    date: "21 دی ماه 1400",
  },
  {
    img: "/assets/article-5.jpg",
    category: "دسته بندی",
    title: "عنوان بلاگ شماره پنج",
    date: "21 دی ماه 1400",
  },
];

export default function Home() {
  return (
    <>
      <MainHeader />
      <main>
        <div className="container">
          <section className="row row-1">
            <div className="left-side">
              <SpecialProduct />
            </div>
            <div className="right-side">
              <MainSlider />
            </div>
          </section>
          <section className="row row-2">
            <ThirdSlider
              elementName=".row-2"
              data={categories}
              type="category"
              title="دسته بندی محصولات"
              breakPoints={{
                200: {
                  slidesPerView: 3,
                },

                900: {
                  slidesPerView: 5,
                },

                1400: {
                  slidesPerView: 8,
                },
              }}
            />
          </section>
          <section className="row row-3">
            <SecondrySlider elementName=".row-3" data={productList1} />
          </section>
          <section className="row categories-brands">
            {categoriesSecond.map((item, index) => {
              return (
                <CategorySecond
                  img={item.img}
                  title={item.title}
                  body={item.body}
                  link={item.link}
                  bgColor={item.bgColor}
                />
              );
            })}
          </section>
          <section className="row product-slider-1">
            <Slider
              elementName=".product-slider-1"
              data={productList2}
              sldTitle="استایل هفته"
              link="#"
              sldImg="/assets/model-1.png"
            />
          </section>
          <br />
          <section className="row brands">
            <img src="/assets/brand-1.png" alt="Brand" className="brand" />
            <img src="/assets/brand-2.png" alt="Brand" className="brand" />
            <img src="/assets/brand-3.png" alt="Brand" className="brand" />
            <img src="/assets/brand-4.png" alt="Brand" className="brand" />
            <img src="/assets/brand-5.png" alt="Brand" className="brand" />
            <img src="/assets/brand-6.png" alt="Brand" className="brand" />
            <img src="/assets/brand-7.png" alt="Brand" className="brand" />
            <img src="/assets/brand-8.png" alt="Brand" className="brand" />
          </section>
          <br />
          <section className="row product-slider-2">
            <Slider
              elementName=".product-slider-2"
              data={productList3}
              sldTitle="عینک زنانه و مردانه"
              link="#"
              sldImg="/assets/model-4.png"
            />
          </section>
          <section className="row articles">
            <ThirdSlider
              elementName=".articles"
              data={articles}
              type="article"
              title="آخرین مقالات"
              breakPoints={{
                200: {
                  slidesPerView: 1,
                },

                900: {
                  slidesPerView: 2,
                },

                1200: {
                  slidesPerView: 4,
                },
              }}
            />
          </section>
        </div>
      </main>
      <MainFooter />
    </>
  );
}
