import './ArticleMini.css';

export default function ArticleMini({img, title, category, date}) {
  return(
    <>
      <div className="article-mini">
        <div className="art-img">
          <img src={img} alt="article" />
        </div>
        <p className="art-category">{category}</p>
        <h3 className='art-title'>{title}</h3>
        <div className="art-bottom">
          <span className="date">
            {date}
            <i className="bx bx-date"></i>
          </span>
          <a href="#" className="link">مشاهده</a>
        </div>
      </div>
    </>
  )
}