import "./CategorySecond.css";

export default function CategorySecond({ img, title, body, link, bgColor }) {
  return (
    <>
      <div className="category-second">
        <div className="cat-img">
          <img src={img} alt="category" />
        </div>
        <h4 className="cat-title">{title}</h4>
        <p className="cat-detail">{body}</p>
        <a href={link} className={bgColor + " cat-link"}>
          مشاهده
        </a>
      </div>
    </>
  );
}
