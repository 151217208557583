import logo from './logo.png';

export default function MainHeader() {
  return (
    <>
      <header className="main-header">
        <div className="container">
          <section className="row-top">
            <a href="#" className="logo">
              <img src={logo} alt="logo" />
            </a>
            <form action="" className="search-bar">
              <div className="search-input">
                <button className="bx bx-search"></button>
                <input type="text" name="search" placeholder="جستجو" />
              </div>
              <div className="category">
                <select name="category">
                  <i className="bx bx-chevron-down"></i>
                  <option>دسته بندی ها</option>
                </select>
              </div>
            </form>
            <div className="right-side">
              <a href="#" className="link bx bx-user"></a>
              <a href="#" className="link bx bx-heart"></a>
              <label className="orders">
                <i className="bx bx-cart"></i>
                <span>سبدخرید</span>
                <span className="order-count">1</span>
                <i className="bx bx-chevron-down"></i>
              </label>
            </div>
          </section>
          <section className="row-bottom">
            <nav>
              <ul className="menu">
                <li>
                  <a href="#">درباره ما</a>
                </li>
                <li>
                  <a href="#">تماس باما</a>
                </li>
                <li>
                  <a href="#">وبلاگ</a>
                </li>
                <li>
                  <a href="#">فروشگاه</a>
                </li>
                <li>
                  <a href="#" className="active">خانه</a>
                </li>
              </ul>
            </nav>
          </section>
        </div>
      </header>
    </>
  );
}
