// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./SecondrySlider.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import Product from "../Product/Product";

export default function SecondrySlider({ elementName, data }) {
  return (
    <>
      <div className="sec-slider">
        <Swiper
          dir="rtl"
          slidesPerView={3}
          spaceBetween={50}
          navigation={{
            prevEl: elementName + " .sec-slider .prev",
            nextEl: elementName + " .sec-slider .next",
            disabledClass: "disable",
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          width={700}
          breakpoints={{
            900: {
              slidesPerView: 2,
            },

            1200: {
              slidesPerView: 3,
            },
          }}
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide>
                <Product
                  title={item.title}
                  img={item.img}
                  curntPrice={item.curntPrice}
                  prevPrice={item.prevPrice}
                  offPrice={item.offPrice}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div>
          <div className="sld-btns">
            <span className="sld-btn next bx bx-chevron-left"></span>
            <span className="sld-btn prev bx bx-chevron-right"></span>
          </div>
          <div className="off-price">
            <h2>تخفیفات شگفت انگیز</h2>
            <p>تخفیفات روزانه تا سقف 50 درصد</p>
          </div>
        </div>
      </div>
    </>
  );
}
