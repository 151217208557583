// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./ThirdSlider.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Category from "../Category/Category";
import ArticleMini from "../ArticleMini/ArticleMini";

export default function ThirdSlider({ elementName, data, type, title, breakPoints }) {

  const categorySlider = (item)=> {
    return <Category title={item.title} img={item.img} />
  }
  const articleSlider = (item)=> {
    return <ArticleMini
     title={item.title} 
     img={item.img} 
     category={item.category}
     date={item.date}
     />
  }

  const getElements = (item)=> {
    switch (type) {
      case 'category':
        return categorySlider(item);
      case 'article':
        return articleSlider(item);
    }
  }

  return (
    <>
      <div className="thr-slider">
        <div className="header">
          <div className="sld-btns">
            <span className="sld-btn next bx bx-chevron-left"></span>
            <span className="sld-btn prev bx bx-chevron-right"></span>
          </div>
          <h2 className="title">{title}</h2>
        </div>
        <Swiper
          dir="rtl"
          spaceBetween={30}
          breakpoints={breakPoints}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: elementName + " .thr-slider .prev",
            nextEl: elementName + " .thr-slider .next",
            disabledClass: "disable",
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                {
                  getElements(item)
                }

              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
